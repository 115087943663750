<template>
  <section>
    <div class="top-questions container">
      <div class="top-questions__title">
        Our Top Questions :
      </div>
      <div class="top-questions__list">
        <div
          v-for="question, index in questions"
          :key="index"
          class="top-questions__item"
        >
          <router-link to="/help-centre" class="text-justify d-flex justify-content-between align-items-center w-100">
            <span>
              {{ question }}
            </span>
            <i class="fa fa-angle-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    questions: [
      'How do I create my book?',
      'Do you offer free shipping?',
      'I’m having trouble sign in to my account',
      'Where can I enter my promo code?',
      'My delivery address is wrong',
      'My Order Is late',
      'Can I add a gift note or receipt?',
      'How do I create an account?',
    ]
  })
}
</script>

<style lang="scss" scoped>
.top-questions {
  text-align: center;
  position: relative;

  &__title {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 10px;
    color: hsla(0, 0%, 0%, 0.5);
    padding: 20px;
    position: relative;
    top: -30px;
    background-color: hsla(0, 100%, 100%, 1);
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }

  &__item {
    text-align: left;
    padding: 12px 0;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.2);
  }

  @media screen and (min-width: 992px) {
    &__title {
      font-size: 20px;
      padding: 24px;
      top: -40px;
      width: 60%;
      margin: 0 auto;
    }

    &__list {
      column-gap: 100px;
    }
  }
}
</style>