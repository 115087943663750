<template>
  <div :class="['banner', { page, small }]">
    <div class="clamp-banner">
      <img class="banner-accent" :src="'/img/' + page + '-banner-accent.png'">
      <div
        v-if="!small"
        class="banner__title-box"
      >
        <h2 class="banner__title">NEED HELP?</h2>
        <h3 class="banner__subtitle">
          Ask a Question<br>
          or Search Our Treasure Trove of Knowledge
        </h3>
      </div>
    </div>
    <div class="banner__search container">
      <SearchBar />
    </div>
  </div>
</template>

<script>
import SearchBar from './SearchBar.vue'
export default {
  components: {
    SearchBar
  },
  props: {
    page: {
      type: String,
      default: 'about-us',
    },
    small: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("/img/banner/Banner_Yogya.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 300px;
  position: relative;

  &__clamp {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    margin: 0 auto;
    position: relative;
  }

  &__title-box {
    max-width: 130px;
  }

  &__title {
    font-family: 'Lato';
    font-size: 12px;
    color: white;
  }

  &__subtitle {
    font-family: 'Lato';
    font-size: 10px;
    color: white;
  }

  &__search {
    top: -60px;
    position: relative;
  }

  &__search-container {
    position: absolute;
    bottom: -48px;
    width: calc(100% - 32px);
  }

  @media screen and (min-width: 576px) {
    &__clamp {
      max-width: 540px;
    }
  }

  @media screen and (min-width: 768px) {
    &__clamp {
      max-width: 720px;
    }
  }


  @media screen and (min-width: 1200px) {
    &__clamp {
      max-width: 1140px;
    }
  }
}
</style>