<template>
    <div class="create-post">
      <base-header type="gradient-success" class="pb-3 pt-1 pt-md-8">
      <!-- <h1 style="color: white" v-if="!loading">{{ productName }}</h1> -->
      </base-header>

      <div class="mt--5 col-xl-12 order-xl-1">
        <card shadow style="padding:0">
          <content-placeholders v-show="loading" class="m-5">
              <content-placeholders-heading :img="true" />
              <content-placeholders-text :lines="2" />
              <content-placeholders-heading :img="true" />
              <content-placeholders-text :lines="2" />
            </content-placeholders>
            <div slot="header" class="bg-white border-0" v-show="!loading">
                <div class="row align-items-center">
                    <div class="col-4">
                        <h3 class="mb-0">{{ productName }}</h3>
                    </div>
                    <div class="col-8 text-right item-menu">
                      <div @click="duplicateModel()" class="btn btn-sm btn-primary">Duplicate</div>
                      <div @click="deleteModel()" class="btn btn-sm btn-danger">Delete</div>
                      <a :href="'/preview/'+model.url" class="btn btn-sm btn-primary">View Product Page</a>
                    </div>
                </div>
            </div>
            <template>
                <form @submit.prevent v-show="!loading">
                    <div class="container">
                      <div :class="{ invisible: !error }" class="err-message">
                        <p><span>Error:</span>{{ this.errorMsg }}</p>
                      </div>
                      <div class="pl-lg-4">
                        <div class="row">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Product Name"
                                          placeholder="active | archived/inactive"
                                          input-classes="form-control-alternative"
                                          v-model="model.name"
                                          @input="updateProductUrl()"
                              />
                          </div>
                            
                          <div class="col-lg-6">
                            <base-input alternative=""
                                          label="url"
                                          placeholder="your-book-url"
                                          input-classes="form-control-alternative"
                                          v-model="model.url"
                            />
                          </div>
                          <div class="col-lg-6">
                            <base-input alternative=""
                                            label="Status"
                                            placeholder="active | archived"
                                            input-classes="form-control-alternative"
                                            v-model="model.status"
                                />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                                <base-input alternative=""
                                            label="Priority"
                                            placeholder="1-1000"
                                            input-classes="form-control-alternative"
                                            v-model="model.priority"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <base-input alternative=""
                                            label="Price"
                                            placeholder="189000"
                                            input-classes="form-control-alternative"
                                            v-model="model.price"
                                />
                            </div>
                            <div class="col-lg-6">
                                <base-input alternative=""
                                            label="Price Before Discount (if applicable)"
                                            placeholder="289000"
                                            input-classes="form-control-alternative"
                                            v-model="model.priceBeforeDiscount"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <base-input alternative=""
                                            label="Age"
                                            placeholder="0-3 | 3-6 | 6-12 | All Ages"
                                            input-classes="form-control-alternative"
                                            v-model="model.ageCategory"
                                />
                            </div>
                            <div class="col-lg-6">
                                <base-input alternative=""
                                            label="Language"
                                            placeholder="English | Bahasa Indonesia"
                                            input-classes="form-control-alternative"
                                            v-model="model.languageCategory"
                                />
                            </div>
                            <div class="col-lg-6">
                                <base-input alternative=""
                                            label="Category"
                                            placeholder="Personalized Book, Flip-Flap Book, Hardcover"
                                            input-classes="form-control-alternative"
                                            v-model="model.category"
                                />
                            </div>

                            <div class="col-lg-6">
                                <base-input alternative=""
                                            label="Type"
                                            placeholder="Personalized Book | Book | Kit | Other"
                                            input-classes="form-control-alternative"
                                            v-model="model.type"
                                />
                            </div>
                        </div>
                        <div class="row" v-if="model.category == 'Kreart'">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="Design By"
                                          placeholder="Designer Name"
                                          input-classes="form-control-alternative"
                                          v-model="model.designer"
                              />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                              <base-input alternative=""
                                          label="img"
                                          placeholder="/img/book-url/samplecover.jpg"
                                          input-classes="form-control-alternative"
                                          v-model="model.img"
                              />
                          </div>
                        </div>
                        <div class="preview mb-3">
                          <h5>Images</h5>
                          <div class="images-uploader">
                              <label for="book-image" v-for="image, index in model.images" :key="index" class="image-upload"
                                :style="{ 'background-image': `url(${image})` }" @click="setImageIndex(index)">
                                <!-- <div class="fa fa-arrow-left" @click="moveImagePrev(index)"></div>
                                <div class="fa fa-arrow-right" @click="moveImageNext(index)"></div> -->
                              </label>                              
                            <label for="book-image" class="image-upload new-upload" @click="setImageIndex(model.images.length)">+</label>
                          </div>
                          <h6 v-if="(uploading > 0)">{{ `| Uploading.. (${uploadValue}%)`}}</h6>
                        </div>
                        <div class="blog-info">
                          <div class="upload-file">
                            <input type="file" ref="productPhoto" id="book-image" @change="previewImage" accept=".png, .jpg, ,jpeg, .webp, .svg" />
                          </div>
                        </div>
                        <div><h5>Description</h5></div>
                        <div class="editor">
                          <vue-editor :editorOptions="editorSettings" v-model="model.description" useCustomImageHandler/>
                        </div>
                        <div class="blog-actions">
                          <button class="btn btn-sm btn-primary" @click="updateModel">Save Changes</button>
                          <!-- <router-link class="router-button" :to="{ name: 'BlogPreview' }">Preview Changes</router-link> -->
                        </div>
                      </div>    
                    </div>
                </form>
            </template>
        </card>
      </div>
    </div>
  </template>
  
<script>
  import firebase from "firebase/app";
  import "firebase/storage";
  import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();
  import Quill from "quill";
  window.Quill = Quill;
  const ImageResize = require("quill-image-resize-module").default;
  Quill.register("modules/imageResize", ImageResize);
  export default {
    name: "CreatePost",
    data() {
      return {
        id: null,
        file: null,
        error: null,
        errorMsg: null,
        loading: null,
        uploadValue: 0,
        uploading: false,
        imageIndex: 0,
        imageData: null,
        model: {
          name: '',
          img: null,
          images: []
        },
        productName: "",
        currentProduct: null,
        editorSettings: {
          modules: {
            imageResize: {},
          },
        },
      };
    },
    async mounted() {
      this.loading = true;
      this.routeID = this.$route.params.id;
      this.getModel();
    },
    methods: {
      moveImageNext(index){
        console.log('moveNext');
        if (index !== -1 && index < this.model.images.length - 1) {
          let el = this.model.images[index];
          this.model.images[index] = this.model.images[index + 1];
          this.model.images[index + 1] = el;
        }
      },
      moveImagePrev(index){
        console.log('movePrev');
        if (index > 0) {
          let el = this.model.images[index];
          this.model.images[index] = this.model.images[index - 1];
          this.model.images[index - 1] = el;
        }
      },
      setImageIndex(index){
        this.imageIndex = index;
      },
      previewImage(event) {
        this.uploadValue=0;
        this.uploading=true;
        this.model.images[this.imageIndex] = 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921';
        this.imageData = event.target.files[0];
        this.onUpload()
      
        // this.file = this.$refs.productPhoto.files[0];
        // console.log(this.file);
        // const fileName = this.file.name;
        // this.$store.commit("fileNameChange", fileName);
        // this.$store.commit("createFileURL", URL.createObjectURL(this.file));
      
      },

      onUpload(){
        const storageRef=firebase.storage().ref(`products/${this.model.url}/${this.imageIndex}`).put(this.imageData);
        storageRef.on(`state_changed`, snapshot => {
          this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        },
        error=>{console.log(error.message)},
        ()=>{
          this.uploadValue=100;
          this.uploading=false;
          storageRef.snapshot.ref.getDownloadURL().then((imageUrl)=>{
            this.updateImages(imageUrl)
          });
        }      
        );
      },
      async updateImages(imageUrl) {
        this.model.images[this.imageIndex] = imageUrl;
        // console.log('updateImages', imageUrl);
        try{
          await db.collection("products").doc(this.routeID).update({images: this.model.images, img: this.model.images[0]});
          console.log('upload success');
          alert('Your image has been uploaded');
          this.$router.go()
        } catch(err) {
          alert(err)
        }
      },
      updateProductUrl(){
        // console.log(this.model.name.toLowerCase());
        this.model.url = this.model.name.toLowerCase().replace(/\s/g,"-");
      },

      async duplicateModel() {
        let productNewName = prompt("Please enter your product name:", "Copy of " + this.model.name);
        if (productNewName == null || productNewName == "") {
          alert("User cancelled the prompt.");
        } else {
          this.loading = true;
          alert('Duplicating product to ' + productNewName + ', you will be redirected to the new product');
          let duplicateModel = JSON.parse(JSON.stringify(this.model));
          duplicateModel.name = productNewName;
          duplicateModel.url = productNewName.toLowerCase().replace(/\s/g,"-")
          duplicateModel.updated_at = new Date();
          duplicateModel.date = new Date();
          duplicateModel.views = 0;
          console.log(duplicateModel);

          const dataBase = await db.collection("products").doc();
          await dataBase.set(duplicateModel).then(
            () => {
              // console.log(result);
              this.$router.push({ name: "Edit Product", params: { id: dataBase.id } });
              this.loading = false;
              this.routeID = dataBase.id;
              this.getModel();
            }
          );
        }
      },

      async deleteModel() {
        if (confirm("This action can't be undone, if you want to remove the book from customer display, you can update the status to archived instead. Are you sure you want to delete this product?") == false) {
          console.log("User cancelled the prompt.");
        } else {
          this.loading = true;
          await db.collection("products").doc(this.routeID).delete().then(
            () => {
              alert('your product has sucessfully been deleted, you will be redirected to products list page');
              this.$router.push({ name: "products"});
            }
          );
        }
      },

      async getModel() {
        const thisVue = this;
        await db.collection("products").doc(this.routeID).get()
          .then(snapshot => {
            // console.log(snapshot.data());
            thisVue.model = snapshot.data();
            thisVue.productName = thisVue.model.name.split('').join('');
            thisVue.model.description = (thisVue.model.description) ? thisVue.model.description : '';
            // console.log(thisVue.model.name);
            // console.log("thisVue.productName", thisVue.productName);
            this.loading = false;
          });
      },
  
      async updateModel() {
        const dataBase = await db.collection("products").doc(this.routeID);
        if (this.model.name.length !== 0) {
          console.log('updating model', this.model);
          if (this.file) {
            this.loading = true;
            const storageRef = firebase.storage().ref();
            const docRef = storageRef.child(`documents/ProductCoverPhotos/${this.$store.state.productPhotoName}`);
            docRef.put(this.file).on(
              "state_changed",
              (snapshot) => {
                console.log(snapshot);
              },
              (err) => {
                console.log(err);
                this.loading = false;
              },
              async () => {
                const downloadURL = await docRef.getDownloadURL();
  
                this.model.productPhoto = downloadURL;
                this.model.productPhotoName = this.productPhotoName;
                
                await dataBase.update(this.model);
                await this.$store.dispatch("updatePost", this.routeID);
                this.loading = false;
                alert('Your Data has been Updated');
                // this.$router.push({ name: "Edit Product", params: { id: dataBase.id } });
              }
            );
            return;
          }
          this.loading = true;
          if(this.model.img == '')
            this.model.img = 'img/'+this.model.url+'/samplecover.jpg';
          
          this.model.updated_at = new Date();
          await dataBase.update(this.model);
          await this.$store.dispatch("updatePost", this.routeID);
          this.loading = false;
          this.$router.push({ name: "Edit Product", params: { id: dataBase.id } });
          return;
        } else {
          alert('name cannot be empty');
        }
        this.error = true;
        this.errorMsg = "Please ensure Blog Title & Blog Post has been filled!";
        setTimeout(() => {
          this.error = false;
        }, 5000);
        return;
      },
    },
    computed: {
      profileId() {
        return this.$store.state.profileId;
      },
      productPhotoName() {
        return this.$store.state.productPhotoName;
      },
      productDescription: {
        get() {
          return this.$store.state.productDescription;
        },
        set(payload) {
          this.$store.commit("newProduct", payload);
        },
      },
    },
  };
  </script>
  
  <style lang="scss">
  .item-menu btn {
    display: inline-block;
  }
  .create-post {
    position: relative;
    height: 100%;
  
    button {
      margin-top: 0;
    }
  
    .router-button {
      text-decoration: none;
      color: #fff;
    }
  
    .button,
    .router-button {
      transition: 0.5s ease-in-out all;
      align-self: center;
      font-size: 14px;
      cursor: pointer;
      border-radius: 20px;
      padding: 12px 24px;
      color: #fff;
      background-color: #303030;
      text-decoration: none;
  
      &:hover {
        background-color: rgba(48, 48, 48, 0.7);
      }
    }
  
    .container {
      position: relative;
      height: 100%;
      padding: 10px 25px 60px;
    }
  
    // error styling
    .invisible {
      opacity: 0 !important;
      height: 0;
    }
  
    .err-message {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      color: #fff;
      margin-bottom: 10px;
      background-color: #303030;
      opacity: 1;
      transition: 0.5s ease all;
  
      p {
        font-size: 14px;
      }
  
      span {
        font-weight: 600;
      }
    }
  
    .blog-info {
      display: flex;
      margin-bottom: 20px;
  
      input:nth-child(1) {
        min-width: 300px;
      }
  
      input {
        transition: 0.5s ease-in-out all;
        padding: 10px 4px;
        border: none;
        border-bottom: 1px solid #303030;
  
        &:focus {
          outline: none;
          box-shadow: 0 1px 0 0 #303030;
        }
      }
  
      .upload-file {
        flex: 1;
        margin-left: 16px;
        position: relative;
        display: flex;
  
        input {
          display: none;
        }
  
        .preview {
          margin-left: 16px;
          height: 20pt;
          text-transform: initial;
        }
  
        span {
          font-size: 12px;
          margin-left: 16px;
          align-self: center;
        }
      }
    }
  
    .editor {
      height: 60vh;
      display: flex;
      flex-direction: column;
  
      .quillWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
  
      .ql-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: scroll;
      }
  
      .ql-editor {
        padding: 20px 16px 30px;
      }
    }
  
    .blog-actions {
      margin-top: 32px;
  
      button {
        margin-right: 16px;
      }
    }
  }
  </style>
  