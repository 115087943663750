<template>
  <form @submit.prevent>
    <label class="d-block">
      <span class="px-1 px-lg-2 text-orange">Full Name</span>
      <base-input
        placeholder="Full Name"
        addon-left-icon="fas fa-user"
        v-model="fullName"
      />
    </label>     


    <label class="d-block">
      <span class="px-1 px-lg-2 text-orange">Email</span>
      <base-input
        placeholder="Email"
        addon-left-icon="fas fa-envelope"
        v-model="email"
      />
    </label>

    <label class="d-block">
      <span class="px-1 px-lg-2 text-orange">Phone</span>
      <base-input
        placeholder="Phone"
        addon-left-icon="fas fa-phone"
        v-model="phoneNumber"
      />
    </label>
    <label class="d-block">
      <span class="px-1 px-lg-2 text-orange">Message</span>
      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Write a your message here ..." v-model="message"></textarea>
    </label>

    <base-button
      block
      type="primary"
      class="my-5"
      @click="sendMail"
    >
      Submit Your Information
    </base-button>
  </form>
</template>
<script>
export default {
    name: 'bookabook',
    data() {
        return {
            fullName: '',
            email: '',
            phoneNumber: '',
            message: ''
        }
    },
    methods: {
      sendMail(){
        const mailto = `mailto:hello@bookabook.id?subject=Hello%20Bookabook%20from%20${this.fullName}&cc=${this.email}&body=${this.message}%0A%0AYou%20can%20contact%20me%20via%20${this.email}%20or%20${this.phoneNumber},%0A%0AThank%20you%20and%20best%20regards,%0A%0A${this.fullName}`;
        window.open(mailto, '_blank');
      }
    }    
  }
</script>
