<template>
    <div class="byme row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="row my-3 mx-3" style="text-align:left; flex-direction: column;">
                <div>
                    <h1>Books By Me</h1>
                </div>
                <div class="mb-3" v-if="orders.length > 0">
                    <a href="https://drive.google.com/file/d/1fztuiJ1wmjGlepeVNDD5q10GwxEegTcJ/view?usp=drive_link" class="btn btn-warning" target="_blank">
                        Byme Tutorial
                    </a>
                    <a href="https://drive.google.com/file/d/1xBK1TSrzUNihAk3Xz8kOuTHXEl3Jf22c/view?usp=drive_link" class="btn btn-primary" target="_blank">
                        Download Byme Template
                    </a>
                </div>
            </div>
            <div class="row my-3" style="text-align:left">
                <div class="col-md-1 col-lg-2 col-xs-12"></div>
                <div class="col-md-10 col-lg-8 col-xs-12 px-4 my-5">
                    <div v-if="orders.length == 0" >
                        <base-input alternative=""
                            label="Please input your Byme code here"
                            placeholder="ByMe code"
                            input-classes="form-control-alternative"
                            v-model="model.byme_code"
                        />
                        <div class="btn btn-primary float-right" @click="checkBymeCode()">Check Code</div>
                    </div>

                    <div v-if="orders.length > 0">
                        <div class="table-responsive">
                            <base-table class="table align-items-center table-flush"
                                        tbody-classes="list"
                                        :data="orders" v-show="orders.length > 0">
                            <template slot="columns">
                                <th>#</th>
                                <th>Byme Code</th>
                                <th>OrderID</th>
                                <th>Items</th>
                            </template>

                            <template slot-scope="{row}">
                                <td class="upload">
                                    <div v-for="product, index in row.cart" :key="index">
                                        <div v-if="(product.productName=='Byme Kit') || (product.productName=='Byme Kit Digital')">
                                            <!-- {{ bymes[bymes.findIndex((order) => order.code == product.BymeCode)] }} -->
                                            <div v-if="bymes[bymes.findIndex((order) => order.code == product.BymeCode)].loaded">
                                                <label class="btn-sm btn-primary" v-if="bymes[bymes.findIndex((order) => order.code == product.BymeCode)].projectExist" @click="openProjectByme(product.BymeCode)">Open Project</label>
                                                <label class="btn-sm btn-primary" v-else @click="createProjectByme(row, product)">Start Project</label>
                                            </div>
                                        </div>
                                        <div v-else>-</div>
                                    </div>
                                </td>

                                <td class="items">
                                    <div v-for="product, index in row.cart" :key="index">
                                    <b v-if="(product.productName=='Byme Kit') || (product.productName=='Byme Kit Digital')">
                                        {{ product.BymeCode || 'waiting for payment' }}</b>
                                    <span v-else>-</span>
                                    </div>
                                </td>
                                <td scope="row">
                                    <div class="media align-items-center clickable" @click="view(row.id)">
                                        <a href="#" class="avatar mr-3" v-show="row.img">
                                        <img alt="Image placeholder" :src="row.img">
                                        </a>
                                        <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.orderID}}</span>
                                        </div>
                                    </div>
                                </td>
                                <td class="items">
                                    <div v-for="product, index in row.cart" :key="index">
                                    <b>{{ product.productName }}</b>
                                    <span style="color: gray"> for </span>
                                    <b>{{ product.childname }}</b>
                                    </div>
                                </td>
                            </template>

                            </base-table>
                        </div>
                        <!-- <div>{{ bymes }}</div> -->
                        <div v-if="byme.code">
                            <hr/>
                            <div class="btn mb-2" :class="(selectedPage == 'cover') ? 'btn-primary': 'btn-secondary'" @click="selectPage('cover')">Cover</div>
                            <div class="btn mb-2" :class="(selectedPage == 'page-'+index) ? 'btn-primary': 'btn-secondary'" v-for="index in 10" :key="index" @click="selectPage('page-'+index)">Page {{ index }}</div>
                            <div class="btn mb-2" :class="(selectedPage == 'back-cover') ? 'btn-primary': 'btn-secondary'" @click="selectPage('back-cover')">Back Cover</div>
                            <div class="my-3"></div>
                            <div class="upload-file">
                                <label class="btn-sm btn-primary" :for="selectedPage">Choose {{ selectedPage }} image</label>
                                <input type="file" style="display: none;" ref="productPhoto" :id="selectedPage" @change="previewImage" accept=".png, .jpg, ,jpeg, .webp, .svg" />
                            </div>
                            <div class="preview mb-3" v-if="byme.data">
                                <h5>Preview</h5>
                                <div class="byme-container">
                                    <img class="canvas" :src="pages[selectedPage]">
                                    <!-- <img class="canvas" v-if="model.img" :src="model.img"> -->
                                </div>
                            </div>
                            <!-- <pre>{{ byme }}</pre> -->

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";
import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

export default {
    name: 'bookabook',
    data() {
    return {
        menuSelected: 'account-details',
        orderLoaded: false,
        model: {
          byme_code: '',
          username: '',
          email: this.$store.state.profileEmail,
          firstName: this.$store.state.profileFirstName,
          lastName: this.$store.state.profileLastName,
          address: '',
          city: '',
          country: '',
          zipCode: '',
          about: '',
        },
        bymes: [],
        byme: {
            code: '',
            data: {},
        },
        pages: {
            'cover': '',
            'page-1':'',
            'page-2':'',
            'page-3':'',
            'page-4':'',
            'page-5':'',
            'page-6':'',
            'page-7':'',
            'page-8':'',
            'page-9':'',
            'page-10':'',
            'back-cover': '',
        },
        selectedPage: 'cover',
        orders: [],
    }
    },
    async created() {
        firebase.auth().onAuthStateChanged(async (user) => {
            await this.$store.dispatch("getCurrentUser", user);
            this.loadProfileData();
            this.loadOrders();
        })
    },
    computed: {
        products() {
            return this.$store.state.products;
        },
        isAdmin(){
            return this.$store.state.profileAdmin;
        },
    },
    methods: {
    loadProfileData(){
        let data = this.$store.state.profileData;
        if(this.$store.state.profileData){
            this.model.email = data.email;
            this.model.firstName = data.firstName;
            this.model.lastName = data.lastName;
        }
    },
    async loadOrders(){
        this.orderLoaded = false;
        this.orders = [];
        const dataBase = await db.collection('orders').orderBy("date", "desc").where("email",'==',this.$store.state.profileEmail);
        const dbResults = await dataBase.get();
        if(dbResults.empty)
            this.orderLoaded = true;
            dbResults.forEach((doc) => {
                const data = doc.data();
                if (data.isByme) {
                    data.id = doc.id;
                    this.orders.push(data);
                    data.cart.forEach((product) => {
                        console.log('checking prod', product);
                        if(product.BymeCode)
                            {  
                                // this.bymes[product.BymeCode] = ({ loaded: false, projectExist: false });
                                this.bymes.push({ code: product.BymeCode, loaded: false, projectExist: false });
                                this.loadByme(product.BymeCode);
                            }
                    });
                }
                this.orderLoaded = true;
            });
        console.log(dbResults);
    },
    async loadByme(code){
        console.log('loadByme', code);
        const dbResults = await db.collection('bymes').doc(code).get();
        console.log('dbResults', dbResults);
        const index = this.bymes.findIndex((order) => order.code == code);
        
        if(!dbResults.exists){
            this.bymes[index].loaded = true;
            this.bymes[index].projectExist = false;
            // console.log('this.bymes[code]', code , this.bymes[index]);
        } else {
            const data = dbResults.data();
            this.bymes[index].data = data;
            this.bymes[index].loaded = true;
            this.bymes[index].projectExist = true;
            console.log(index, this.bymes[index]);
        }
    },
    async checkBymeCode(){
        this.orderLoaded = false;
        this.orders = [];
        let code_found = false;
        const dataBase = await db.collection('orders').where("isByme",'==',true);
        const dbResults = await dataBase.get();
        if(dbResults.empty){
            this.orderLoaded = true;
            alert('result error');
        }
        dbResults.forEach((order_ref) => {
            const order = order_ref.data();
            console.log(order);
            if(order.paymentStatus == 'Paid'){
              order.cart.forEach((product) => {
                // console.log("check product.BymeCode", product.BymeCode);
                if (product.BymeCode == this.model.byme_code){
                    code_found = true;
                    
                    // let data = order;
                    let data = Object.assign({}, order);
                    data.cart = [product];
                    
                    if(!this.orders.some((order)=> order.orderID = data.orderID))
                        this.orders.push(data);
                    
                    this.bymes.push({ code: product.BymeCode, loaded: false, projectExist: false });
                    this.loadByme(product.BymeCode);
                    // data.cart.forEach((product) => {
                    //     if(product.BymeCode)
                    //     // if(product.BymeCode && product.BymeCode == this.model.byme_code)
                    //         {  
                    //             // this.bymes[product.BymeCode] = ({ loaded: false, projectExist: false });
                    //             this.bymes.push({ code: product.BymeCode, loaded: false, projectExist: false });
                    //             this.loadByme(product.BymeCode);
                    //         }
                    // });
                }
              })
            }
        });
        if(code_found){
            alert('Byme Code Found..');
            
        } else {
            alert('Byme Code Not Found!');
        }
    },
    async createProjectByme(order, product){
        const code = product.BymeCode;
        console.log("createProjectByme", code);
        try {
            await db.collection('bymes').doc(code).set({_id: code, order: order, product: product});        
            alert('Project successfully created');
            this.loadByme(code);
            this.openProjectByme(code);
        } catch (e){
            console.log(e);
        }
    },
    openProjectByme(code){
        const index = this.bymes.findIndex((order) => order.code == code);
        this.byme.code = code;
        this.byme.data = this.bymes[index].data;
        this.pages = {
                'cover': this.byme.data['cover'] ? this.byme.data['cover'] : '',
                'page-1': this.byme.data['page-1'] ? this.byme.data['page-1'] : '',
                'page-2': this.byme.data['page-2'] ? this.byme.data['page-2'] : '',
                'page-3': this.byme.data['page-3'] ? this.byme.data['page-3'] : '',
                'page-4': this.byme.data['page-4'] ? this.byme.data['page-4'] : '',
                'page-5': this.byme.data['page-5'] ? this.byme.data['page-5'] : '',
                'page-6': this.byme.data['page-6'] ? this.byme.data['page-6'] : '',
                'page-7': this.byme.data['page-7'] ? this.byme.data['page-7'] : '',
                'page-8': this.byme.data['page-8'] ? this.byme.data['page-8'] : '',
                'page-9': this.byme.data['page-9'] ? this.byme.data['page-9'] : '',
                'page-10': this.byme.data['page-10'] ? this.byme.data['page-10'] : '',
                'back-cover': this.byme.data['back-cover'] ? this.byme.data['back-cover'] : '',
        };
    },
    selectPage(page){
        this.selectedPage = page;
    },
    previewImage(event) {
        // console.log('event', event);
        this.uploadValue=0;
        this.model.img = 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921';
        this.imageData = event.target.files[0];
        console.log(this.imageData);
        this.onUpload(this.byme.code, event.target.id)
      },
    onUpload(code, page){
        const storageRef = firebase.storage().ref(`bymes/${code}/${page}`).put(this.imageData);
        storageRef.on(`state_changed`, snapshot => {
            this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        },
        error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then((imageUrl)=>{
            this.model.img = imageUrl;
            // console.log(this.newImageUrl)
            this.updateCover(code, page, imageUrl);
            });
        }      
        );
    },
    updateCover(code, page, imageUrl) {
        let pages = {};
        pages[page] = imageUrl;
        this.pages[page] = imageUrl;
        console.log('update bymes',code, pages);
        db.collection("bymes").doc(code).update(pages)
            .then(() => {
                console.log('upload success');
                alert('Your cover has been updated');
            })
            .catch(err => {
                alert(err)
            })
    },
    logout() {
        if (confirm("Are you sure you want to logout?") == true) {
            firebase.auth().signOut();
            window.location.reload();
        }
      },

  },
}

</script>
