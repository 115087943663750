<template>
  <div>
    <PageBanner page="contact-us" small />

    <main>
      <section class="py-6 py-md-8" style="background-color: #f7f7f7;">
        <div class="container">
          <div class="navlinks">
            <router-link
              v-for="(item, index) in navLinks"
              :key="index"
              :to="item.to"
              class="navlinks__item"
            >
              <div>
                <img :src="item.icon">
                <br>
                <span>
                  {{ item.label }}
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </section>

      <TopQuestions />

      <NeedHelp />
    </main>
  </div>
</template>

<script>
import NeedHelp from '../../components/HelpCentre/NeedHelp.vue'
import PageBanner from '../../components/HelpCentre/PageBanner.vue'
import TopQuestions from '../../components/HelpCentre/TopQuestions.vue'

export default {
  components: {
    PageBanner,
    NeedHelp,
    TopQuestions,
  },
  data: () => ({
    navLinks: [
      {
        to: '/about-us',
        label: 'About Us',
        icon: '/img/contact-us-icon-1.png'
      },
      {
        to: '/careers',
        label: 'Careers',
        icon: '/img/contact-us-icon-2.png'
      },
      {
        to: '/company-profile',
        label: 'Company Profile',
        icon: '/img/contact-us-icon-3.png'
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("/img/banner/Banner_Yogya.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 165px;
  
  position: relative;

  &__clamp {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    margin: 0 auto;
    position: relative;
  }

  &__title-box {
    max-width: 130px;
  }

  &__title {
    font-family: 'Lato';
    font-size: 12px;
    color: white;
  }

  &__subtitle {
    font-family: 'Lato';
    font-size: 10px;
    color: white;
  }

  &__search-container {
    position: absolute;
    bottom: -24px;
    width: calc(100% - 32px);
  }

  @media screen and (min-width: 576px) {
    &__clamp {
      max-width: 540px;
    }
  }

  @media screen and (min-width: 768px) {
    &__clamp {
      max-width: 720px;
    }
  }

  @media screen and (min-width: 992px) {
    height: 435px;

    &__clamp {
      max-width: 960px;
    }

    &__title-box {
      max-width: 400px;
    }

    &__title,
    &__subtitle {
      font-size: 36px;
    }

    &__search-container {
      bottom: -56px;
    }
  }

  @media screen and (min-width: 1200px) {
    &__clamp {
      max-width: 1140px;
    }
  }
}

.navlinks {
  max-width: 880px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  &__item {
    background-color: white;
    width: 32%;
    aspect-ratio: 1;
    text-align: center;
    padding: 16px 0;

    img {
      width: 70%;
      aspect-ratio: 1;
    }

    span {
      font-size: 12px;
      padding: 0 4px;
      color: black;
    }
  }

  @media screen and (min-width: 992px) {
    &__item {
      width: 30%;

      span {
        font-size: 20px;
      }
    }
  }
}
</style>