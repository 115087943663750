<template>
  <section>
    <div class="container text-center py-6 pt-lg-8">
      <span class="title">
        STILL NEED HELP?
      </span>
      <br>
      <span class="subtitle">
        Our Support Angels are standing by to answer your questions :)
      </span>
      <base-button
        block
        type="primary"
        @click="toSubmitMessagePage"
      >
        <span class="button-label">
          Send Us a Message
        </span>
      </base-button>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    toSubmitMessagePage () {
      this.$router.push('/help-centre/message')
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  color: hsla(0, 0%, 0%, 1)
}

.subtitle {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 8px;
  color: hsla(0, 0%, 0%, 0.5)
}

.button-label {
  color: white;
  font-size: 12px;
}

@media screen and (min-width: 992px) {
  .title {
    font-size: 36px;
  }

  .subtitle {
    font-size: 36px;
    margin-bottom: 16px;
  }

  .button-label {
    color: white;
    font-size: 18px;
  }

}
</style>