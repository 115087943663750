<template>
    <div class="container" style="margin-top: 50px;">
        <h1><span style="font-family:Arial,sans-serif"><span style="color:#000000"><strong>Request For Data
                        Changes</strong></span></span></h1>

        <p>If you would like to delete or make changes to your account please reach out by messaging us through the form
            below:</p>

        <form @submit.prevent>
            <label class="d-block">
                <span class="px-1 px-lg-2 text-orange">Full Name</span>
                <base-input placeholder="Full Name" addon-left-icon="fas fa-user" v-model="fullName" />
            </label>


            <label class="d-block">
                <span class="px-1 px-lg-2 text-orange">Email</span>
                <base-input placeholder="Email" addon-left-icon="fas fa-envelope" v-model="email" />
            </label>

            <label class="d-block">
                <span class="px-1 px-lg-2 text-orange">Phone</span>
                <base-input placeholder="Phone" addon-left-icon="fas fa-phone" v-model="phoneNumber" />
            </label>
            <label class="d-block">
                <span class="px-1 px-lg-2 text-orange">Message</span>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                    placeholder="Write a your message here ..." v-model="message"></textarea>
            </label>

            <base-button block type="primary" class="my-5" @click="sendMail">
                Submit Your Information
            </base-button>
        </form>

    </div>
</template>
<script>
export default {
    name: 'bookabook',
    data() {
        return {
            fullName: '',
            email: '',
            phoneNumber: '',
            message: 'Hello bookabook, I would like to make some changes to my account: ...'
        }
    },
    methods: {
        sendMail() {
            const mailto = `mailto:hello@bookabook.id?subject=Hello%20Bookabook%20from%20${this.fullName}&cc=${this.email}&body=${this.message}%0A%0AYou%20can%20contact%20me%20via%20${this.email}%20or%20${this.phoneNumber},%0A%0AThank%20you%20and%20best%20regards,%0A%0A${this.fullName}`;
            window.open(mailto, '_blank');
        }
    }
}
</script>
