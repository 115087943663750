<template>
  <div>
    <PageBanner small class="mb-5 mb-lg-7" />

    <main>
      <section class="container section">
        <h2 class="section__title">Careers at Bookabook</h2>
        <hr class="section__divider">
        <h3 class="section__subtitle">Lorem ipsum dolor sit amet consectetur.</h3>
        <p class="section__paragraph">
          Lorem ipsum dolor sit amet consectetur. Sed quam egestas semper neque faucibus condimentum ornare adipiscing elit. Non egestas at risus sed. Tincidunt suspendisse eu ut diam quam tempus. Sed aliquet et commodo augue arcu tortor.
        </p>
        <figure class="section__figure">
          <img class="section__figure-img" src="/img/banner/Banner_Yogya.jpg">
        </figure>
      </section>
      
      <section class="container">
        <MessageForm />
      </section>

      <section class="bg-gray-100 no-job">
        <div class="container text-center">
          <img
            src="/img/icons/briefcase.png"
            class="no-job__image"
          >
          <br>
          <span class="no-job__title">
            Sorry no job opening at the moments
          </span>
          <br>
          <span
            class="no-job__subtitle"
          >
            We open new jobs from time to time, so please check again soon!
          </span>
        </div>
      </section>
    </main>

  </div>
</template>

<script>
import PageBanner from '../../components/HelpCentre/PageBanner'
import MessageForm from '../../components/HelpCentre/MessageForm.vue'

export default {
  components: {
    PageBanner,
    MessageForm,
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/colors';

.section {
  margin-bottom: 48px;

  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: $blue;
  }

  &__subtitle {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: hsla(0, 0%, 0%, 0.7);
  }

  &__paragraph {
    font-size: 12px;
    color: hsla(0, 0%, 0%, 0.6);
  }

  &__divider {
    margin-top: 0px;
    margin-bottom: 12px;
  }

  &__figure {
    margin: 0 auto;
    width: 200px;
    max-width: 100%;
    margin-bottom: 28px;
  }

  &__figure-img {
    width: 100%
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 72px;

    &__title {
      font-size: 32px;
    }

    &__subtitle {
      font-size: 24px;
    }

    &__paragraph {
      font-size: 16px;
    }

    &__divider {
      margin-bottom: 18px;
    }

    &__figure {
      width: 668px;
      margin-bottom: 36px;
    }
  }
}

.no-job {
  padding-top: 16px;
  padding-bottom: 28px;

  &__image {
    width: 76px;
    aspect-ratio: 1;
  }

  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: $black;
  }

  &__subtitle {
    font-size: 12px;
    color: hsla(0%, 0%, 0%, 0.5);
  }

  @media screen and (min-width: 992px) {
    padding-top: 42px;
    padding-bottom: 80px;

    &__image {
      width: 190px;
    }

    &__title {
      font-size: 36px;
    }

    &__subtitle {
      font-size: 36px;
    }

  }
}
</style>