<template>
  <div>
    <PageBanner small class="mb-5 mb-lg-7" />

    <main>      
      <section class="container">
        <MessageForm />
      </section>
    </main>

  </div>
</template>

<script>
import PageBanner from '../../components/HelpCentre/PageBanner'
import MessageForm from '../../components/HelpCentre/MessageForm.vue'

export default {
  components: {
    PageBanner,
    MessageForm,
  }
}
</script>
