<template>
  <div>
    <PageBanner small page="company-profile" class="mb-5 mb-lg-7" />

    <main>
      <section class="container section">
        <h2 class="section__title">Our Story</h2>
        <hr class="section__divider">
        <h3 class="section__subtitle">Lorem ipsum dolor sit amet consectetur.</h3>
        <p class="section__paragraph">
          Lorem ipsum dolor sit amet consectetur. Accumsan dui amet justo vivamus morbi pellentesque mollis vitae. Viverra integer sit enim dictum massa id lectus enim. Libero nisi nibh gravida egestas. Mauris sollicitudin id et nibh nibh curabitur quis auctor. Ipsum feugiat mauris nullam mauris nisl sed ultrices diam. Molestie aenean nunc habitasse dui et. Posuere in massa faucibus porta. Donec vulputate elit feugiat sit blandit dictum integer. Dui nisl in cursus arcu eu nunc laoreet. Ante eu ut molestie potenti eget. Tortor nisi et odio adipiscing bibendum sociis odio.
        </p>
        <figure class="section__figure">
          <img class="section__figure-img" src="/img/banner/2022_Bookabook_Compro.jpg">
        </figure>
        <base-button
          size="sm"
          type="primary"
          @click="onDownload"
        >
          <img src="/img/icons/download.png" alt="download" width="12" class="mr-1">
          Download Primary Profile
        </base-button>
      </section>
    </main>

    <NeedHelp />
  </div>
</template>

<script>
import NeedHelp from '../../components/HelpCentre/NeedHelp.vue'
import PageBanner from '../../components/HelpCentre/PageBanner.vue'

export default {
  components: {
    PageBanner,
    NeedHelp
  },
  methods: {
    onDownload () {}
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/colors';

.section {
  margin-bottom: 48px;

  &__title {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: $blue;
  }

  &__subtitle {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: hsla(0, 0%, 0%, 0.7);
  }

  &__paragraph {
    font-size: 12px;
    color: hsla(0, 0%, 0%, 0.6);
  }

  &__divider {
    margin-top: 16px;
  }

  &__figure {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 16px;
  }

  &__figure-img {
    width: 100%
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 72px;

    &__title {
      font-size: 32px;
    }

    &__subtitle {
      font-size: 24px;
    }

    &__paragraph {
      font-size: 16px;
    }

    &__figure {
      margin-bottom: 24px;
    }
  }
}
</style>